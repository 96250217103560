function Footer() {
    return (
      <div>


{/* Start Footer Section */}
<footer className="footer-section">
  <div className="container relative">
    <div className="sofa-img">
      <img src="images/sofa.png" alt="Image" className="img-fluid" />
    </div>
    <div className="row">
      <div className="col-lg-8">
        <div className="subscription-form">
          <h3 className="d-flex align-items-center"><span className="me-1"><img src="images/envelope-outline.svg" alt="Image" className="img-fluid" /></span><span>Subscribe to Newsletter</span></h3>
          <form action="#" className="row g-3">
            <div className="col-auto">
              <input type="text" className="form-control" placeholder="Enter your name" />
            </div>
            <div className="col-auto">
              <input type="email" className="form-control" placeholder="Enter your email" />
            </div>
            <div className="col-auto">
              <button className="btn btn-primary">
                <span className="fa fa-paper-plane" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="row g-5 mb-5">
      <div className="col-lg-4">
        <div className="mb-4 footer-logo-wrap"><a href="#" className="footer-logo">Black Car Any Time<span>.</span></a></div>
        <p className="mb-4">
        Indulge your loved one with a remarkable anniversary limo service accompanied by a devoted chauffeur for an evening to remember. Our team of experienced chauffeurs guarantees reliable and courteous transportation, ensuring a stress-free and enjoyable experience.

          </p>
        <ul className="list-unstyled custom-social">
          <li><a href="#"><span className="fa fa-brands fa-facebook-f" /></a></li>
          <li><a href="#"><span className="fa fa-brands fa-twitter" /></a></li>
          <li><a href="#"><span className="fa fa-brands fa-instagram" /></a></li>
          <li><a href="#"><span className="fa fa-brands fa-linkedin" /></a></li>
        </ul>
      </div>
      <div className="col-lg-8">
        <div className="row links-wrap">
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><a href="#">About us</a></li>
              <li><a href="#">Services</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Contact us</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><a href="#">Support</a></li>
              <li><a href="#">Knowledge base</a></li>
              <li><a href="#">Live chat</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><a href="#">Jobs</a></li>
              <li><a href="#">Our team</a></li>
              <li><a href="#">Leadership</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <ul className="list-unstyled">
              <li><a href="#">Nordic Chair</a></li>
              <li><a href="#">Kruzo Aero</a></li>
              <li><a href="#">Ergonomic Chair</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="border-top copyright">
      <div className="row pt-4">
        <div className="col-lg-6">
          <p className="mb-2 text-center text-lg-start">Designed and Developed By: <a href="mailto:jafri.shakeel@gmail.com">Shakeel Abbas</a>   {/* License information: https://untree.co/license/ */}
          </p>
        </div>
        {/* <div className="col-lg-6 text-center text-lg-end">
          <ul className="list-unstyled d-inline-flex ms-auto">
            <li className="me-4"><a href="#">Terms &amp; Conditions</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul>
        </div> */}
      </div>
    </div>
  </div>
</footer>
{/* End Footer Section */}

      </div>
    );
  }
  
  export default Footer;
  