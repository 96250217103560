import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';


import s1 from './images/img1.jpg';
import s2 from './images/img2.jpg';
import s3 from './images/img3.jpg';
import s4 from './images/img4.jpg';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

// import ExampleCarouselImage from 'components/CarouselImage';

function MyServices() {
const sty = {background:'white', opacity:'70%',borderRadius:'10px',border:'3px solid white',padding:'5px'}
return (<div>

    <Row xs={1} md={2} className="g-4"  style={{margin:'10px'}}>
        <Col>
          <Card>
            <Card.Img variant="top" src={s1} />
            <Card.Body>
              <Card.Title>Airport Car Service</Card.Title>
              <Card.Text>
              If you are looking for a comfortable and safe airport transfer service in Chicago and the Suburbs. We bring you the safest and most comfortable Airport transportation services.

We offer Chicago O’hare Airport Car Service and Chicago Midway Airport Car & limo Service with Meet and Greet Service.

Where Chauffeur can pick you up from inside the Airport with your name sign. We are the Best Limousine service provider in Chicagoland. Our travel services are available 24/7 in Chicago and Suburbs.
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Img variant="top" src={s2} />
            <Card.Body>
              <Card.Title>Executive Black Car</Card.Title>
              <Card.Text>
              Black Car Everywhere prides itself on offering executive black car & limousine services with professional chauffeurs in greater Chicago areas. It’s not just an executive car service but an entirely new level of travel experience that comes with exclusivity, style, and comfort.

The great thing about our service is that it has a low-price tag because of the low operating cost and minimum overhead. Get comfort and style, to enjoy an executive ride at a highly competitive price.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
     </Row>



     <Row xs={1} md={2} className="g-4" style={{margin:'10px'}}>
        <Col>
          <Card>
            <Card.Img variant="top" src={s3} />
            <Card.Body>
              <Card.Title>Corporate Travel</Card.Title>
              <Card.Text>
              We cater to our corporate clients for car & limousine services with a high priority level. We do believe in developing a long-term business relationship that is based on trust and value addition. As a corporate, you must have multiple ground transportation needs varying with the categories of special events and occasions.
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Img variant="top" src={s4} />
            <Card.Body>
              <Card.Title>Wedding Limo Services</Card.Title>
              <Card.Text>
              If you want exclusive and luxurious Limos to celebrate a wedding ceremony or birthday party, we guarantee you the best limo ride ever. We offer limousine services for all occasions; we go one step further by offering last-minute arrangements.
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
     </Row>
     <div style={{height:'250px'}}>
     &nbsp;
     </div>

    </div>  );
}

export default MyServices;