import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/home";

function App() {
  return (
    <div>
       <Header></Header>
      <Home></Home>
      <Footer></Footer>
    </div>
  );
}

export default App;
