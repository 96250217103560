function Home() {
    return (
      <div id="home">


{/* Start Hero Section */}
<div className="hero">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-5">
        <div className="intro-excerpt">
          <h1>Welcome to <span clsas="d-block">Luxury</span> Car Service</h1>
          <p className="mb-4">Indulge in the epitome of luxury with Elite Wheels, where sophistication meets seamless service. Elevate your travel 
          experience with our fleet of meticulously maintained luxury vehicles and our commitment to unparalleled customer care.<br></br>
          Your Trusted Partner in Transportation: Explore Our Car Services</p>
          {/* <p><a href className="btn btn-secondary me-2">Shop Now</a><a href="#" className="btn btn-white-outline">Explore</a></p> */}
        </div>
      </div>
      <div className="col-lg-7">
        <div className="hero-img-wrap">
          <img src="images/couch.png" className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Hero Section */}


<div className="we-help-section">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-7 mb-5 mb-lg-0">
        <div className="imgs-grid">
          <div className="grid grid-1"><img src="images/img1.jpeg" alt="Untree.co" /></div>
          <div className="grid grid-2"><img src="images/img2.jpg" alt="Untree.co" /></div>
          <div className="grid grid-3"><img src="images/img3.jpeg" alt="Untree.co" /></div>
        </div>
      </div>
      <div className="col-lg-5 ps-lg-5">
        <h2 className="section-title mb-4">Luxury Limo & Executive Black Car Service Chicago</h2>
        <p>
        We offer a variety of Black Cars Services and Private Car Services. You can find us in Chicagoland by simply searching our name on Google. We are a famous Limousine and car service company. We have a variety of chauffeur transportation facilities. Where we can serve you according to your events, you can visit our services page and find the professional transportation facility.</p>
        <p>Their commitment to providing a seamless and professional experience is unwavering, from corporate transportation to serving Chicago’s corporate accounts. Whether arriving at O’Hare Airport or Midway Airport, this black car service in Chicago guarantees a timely and safe arrival, ensuring you reach your destination in style.
          </p>
        <ul className="list-unstyled custom-list my-4">
          <li>Airport Transfer Services</li>
          <li>Hourly Executive Transportation</li>
          <li>Luxury Prom Limo Service Chicago & Suburbs</li>
          <li>Wedding Limo Service</li>
        </ul>
        <p><a herf="#" className="btn">Explore</a></p>
      </div>
    </div>
  </div>
</div>

      </div>
    );
  }
  
  export default Home;
  